import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { EventList } from '/src/components/events'
import { Button } from '/src/components/common'

const EventsPage = () => {
  return (
    <Layout hero={<HeroImage title="Events & Field Days" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col sm={10}>
          <Row>
            <Col sm={6}>
              <p>
                Keep up-to-date with news, promotions and other updates in the Metalcorp community. 
                Read below or join our community for content delivered straight to your inbox. 
              </p>
            </Col>
            <Col sm={6}>
              <div className="d-flex justify-content-end">
                <Button variant="primary">
                  Join Our Community
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <EventList />
        </Col>
      </Row>
    </Layout>
  )
}

export default EventsPage

export const Head = () => <title>Events & Field Days | Metalcorp</title>
